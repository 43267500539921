<!-- 智汇学堂-作业与考试-入口 -->
<template>
  <div
    :class="`container exam-container ${checked ? 'night' : ''}`"
    :style="`background:${checked ? '#000' : 'linear-gradient(0deg, #ECF0FF 0%, #7F9AFF 23%, #345AE5 94%)'}`"
  >
    <div style="overflow: auto; height: 100%;">
      <!-- 提示弹窗 -->
      <!-- <div v-if="!show" class="shadow">
        <div class="tip">
          <div class="left">
            往左滑动至下一题
            <img src="@/assets/img/guideHandleft.png" alt='' />
          </div>
          <div class="right">
            往右滑动至上一题
            <img src="@/assets/img/guideHandright.png" alt='' />
          </div>
        </div>
        <div class="know" @click="clickKnow">我知道了</div>
      </div> -->
      <!-- 次标题与答题卡 -->
      <!-- <div class="subhead">
        <div class="back"><</div>
        <div>{{ paperSubName }}</div>
        <div class="back"></div> -->

        <!-- <div
          class="submit-paper-wrap topics"
          style="color: #18a6f3"
          @click="topicShow"
        >
          <span style="font-size: 12px; color: #999"
            >{{ currentIndex + 1 }}/{{ examTotal }}</span
          >
        </div> -->
      <!-- </div> -->
      <!-- 考试监考 -->
      <div class="datika">
        <div class="left">
          <!-- 考试倒计时 -->
          <!-- v-show="showCountdown" -->
          <div class="daojishi" v-if="!(isExam && !paperType)">
            <template v-if="isExam && paperType">
              剩余时间:
              <van-count-down
                ref="count-down"
                @finish="submitPaper"
                :time="timeLeft"
              >
              </van-count-down>
            </template>
          </div>
          <div>{{ paperSubName }}</div>
          <invigilate v-if="paperInfo.patrolFlag==true || paperInfo.cuttingScreenFlag==1" :info="{...paperInfo,id:workId}"></invigilate>
        </div>
        <div class="right">
          <div class="answerSheetBtn" @click="topicShow">
            <img  class="icon1" src="@/assets/img/datika.png" alt="" srcset="">
            <!-- <img src="@/assets/img/answerSheet.png" alt='' srcset='' /><span
              >答题卡</span
            > -->
          </div>
        </div>
      </div>
      <!-- 题目 -->

        <div
          class="question"
          v-longpress
        >
          <questions
            ref="questions"
            :examList="examList"
            :currentIndex="currentIndex"
            :copyFlag="paperInfo.copyFlag==1?true:false"
            :isExercises="!isExam"
            :selected="!paperType"
            :scoringStatus="paperInfo.scoringStatus==1?true:false"
            :examTotal="examTotal"
            @question-result="questionResult"
            @question-index="questionIndex"
            @upload="handleUpload"
          ></questions>
        </div>
      <div v-if="currentIndex === this.examList.length - 1" class="lastExam">
        已经到最后一题啦！
      </div>
      <!-- 上下题按钮 -->
      <div class="btn">
        <button class="prev" @click="prev">上一题</button>
        <button class="next" @click="next">下一题</button>
      </div>
      <div class="bottom">
        <!-- <div
          @click="submitExam"
          class="submit-paper-wrap topics"
          style="color: #18a6f3"
        >
          <icon-svg
            :iconClass="`${
              checked
                ? 'icon-shensemoshi-jiaojuan023x'
                : 'icon-qiansemoshi-jiaojuan023x'
            }`"
            className="icon-class"
          ></icon-svg>
          <span style="font-size: 12px; color: #999">交卷</span>
        </div> -->
        <!-- <div v-if="!isCollection" v-show="!isOldVersion" @click="collect" class="submit-paper-wrap topics" style="color:#18A6F3">
          <icon-svg :iconClass="`${checked?'icon-shensemoshi-weishoucang3x':'icon-qiansemoshi-weishoucang'}`" className="icon-class"></icon-svg>
          <span style="font-size:12px">收藏</span>
        </div>
        <div v-else v-show="!isOldVersion" @click="cancelCollect" class="submit-paper-wrap topics" style="color:#18A6F3">
          <icon-svg :iconClass="`${checked?'icon-shensemoshi-yishoucang3x':'icon-qiansemoshi-yishoucang'}`" className="icon-class"></icon-svg>
          <span style="font-size:12px;color:#999">收藏</span>
        </div>
        <div @click="openCorrect" class="submit-paper-wrap topics" style="color:#18A6F3">
          <icon-svg :iconClass="`${checked?'icon-shensemoshi-jiucuo3x':'icon-qiansemoshi-jiucuo3x'}`" className="icon-class"></icon-svg>
          <span style="font-size:12px;color:#999">纠错</span>
        </div> -->
        <!-- <div class="submit-paper-wrap" style="color:#18A6F3">
          <icon-svg :iconClass="`${checked?'icon-shensemoshi-beiti3x':'icon-qiansemoshi-beiti3x'}`" className="icon-class"></icon-svg>
          <span style="font-size:12px;color:#999">背题模式</span>
        </div> -->
        <!-- <div
          class="submit-paper-wrap topics"
          style="color: #18a6f3"
          @click="topicShow"
        >
          <icon-svg
            :iconClass="`${
              checked ? 'icon-shensemoshi-tishu3x' : 'icon-qiansemoshi-tishu3x'
            }`"
            className="icon-class-small"
          ></icon-svg>
          <span style="font-size: 12px; color: #999"
            >{{ currentIndex + 1 }}/{{ examTotal }}</span
          >
        </div> -->
        <template v-if="!isExam">
          <div></div>
        </template>
      </div>
      <!-- 答题卡 -->
      <answer-sheet
        v-if="showAnswerSheet"
        :answerCardList="answerCardList"
        :sumed="sumed"
        :isExercises="!isExam"
        :paperType="paperType"
        @submit="submitJob"
        @again="again"
        @close="topicHide"
        @to-current-exam="toCurrentExam"
      ></answer-sheet>
      <!-- 上传窗口 -->
      <uploadCommon ref="uploadCommon"
      @uploadfinish="uploadfinish"
      >
      </uploadCommon>
      <!-- 纠错弹窗 -->
      <!-- <van-popup
      v-model="correctShow"
      closeable
      round
      close-icon="close"
      position="bottom"
      :style="{ height: '70%' }"
      :class="`correctPop ${checked?'night':'day'}`"
      >
      <h3 :style="`text-align:center;color:${checked?'#ddd':'#3D3D3D'}`">我要纠错</h3>
      <div class="correctType" :style="`color:${checked?'#ddd':'#3D3D3D'}`">纠错类型</div>
      <van-radio-group v-model="radio" direction="horizontal" style="display:flex;flex-wrap: wrap;">
          <van-radio name="0">题干或解析错误</van-radio>
          <van-radio name="1">解析不匹配</van-radio>
          <van-radio name="2">答案错误</van-radio>
          <van-radio name="3">其它</van-radio>
      </van-radio-group>
      <div class="correctType" :style="`color:${checked?'#ddd':'#3D3D3D'}`">我要补充</div>
      <van-field
          v-model="message"
          class="wybc"
          @focus="wyChange"
          rows="5"
          type="textarea"
          placeholder="请输入留言"
          :style="`background:${checked?'#404040':'#fff'}`"
          />
          <div class="submit" @click="correctSumbit">提 交</div>
      </van-popup> -->
      <!-- 夜间模式 -->
      <!-- <van-action-sheet v-model="fontSizeSheetShow" title='' class="fontSheet">
        <div class="content-action-sheet">
          <div style="width: 100%;">
              <div class="nightBox">
                  <span class="nightMode">夜间模式</span>
                  <van-switch v-model="checked" size="24px" @change="changeMode"/>
              </div>
              <div class="wzsf">文字缩放</div>
            <yxs-slider @change="onProgressChange" :multipleList="multipleList"/>
          </div>
        </div>
      </van-action-sheet> -->
    </div>
  </div>
</template>

<script>
import uploadCommon from '@/components/commonModule/upload/uploadCommon'
import invigilate from '@/components/commonModule/invigilate/index'
import { Toast, Dialog, Notify } from 'vant'
import Questions from './examQuestions/questions'
import { getQueryString } from '@/assets/js/util.js'
import AnswerSheet from './examAnswerSheet'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import yxsSlider from '@/components/yxsSlider'
import { get } from 'http'
function addAttr (richText) {
  try {
    if (!richText) return ''
    richText = String(richText)
    if (!richText.trim()) return ''
    const divNode = document.createElement('div')
    divNode.innerHTML = richText
    const pNodeList = divNode.getElementsByTagName('p');
    // debugger

    [].forEach.call(pNodeList, (pItem, index) => {
      if (pItem.children.length > 0) {
        // 去除<br>标签
        [].forEach.call(pItem.children, (child) => {
          if (child.tagName && child.tagName.toLocaleLowerCase() === 'br') {
            pItem.removeChild(child)
          }
        })
      }

      if (
        pItem.childNodes.length === 1 &&
        pItem.firstChild.tagName &&
        pItem.firstChild.tagName.toLocaleLowerCase() === 'img'
      ) {
        pItem.setAttribute('block-img', 'true')
      }
    })
    return divNode.innerHTML
  } catch (e) {
    console.error(e)
    return richText
  }
}
function addPrefix (richText, prefix) {
  try {
    if (!richText) return ''
    richText = String(richText)
    if (!richText.trim()) return richText
    const divNode = document.createElement('div')
    divNode.innerHTML = richText
    const pNodeList = divNode.getElementsByTagName('p')
    const spanNode = document.createTextNode(prefix + '')
    if (pNodeList.length > 0) {
      // 富文本
      const pNode = pNodeList[0]
      if (pNode.firstChild) {
        pNode.insertBefore(spanNode, pNode.firstChild)
      } else {
        pNode.appendChild(spanNode)
      }
    } else {
      // 老题，非富文本
      divNode.insertBefore(spanNode, divNode.firstChild)
    }
    return divNode.innerHTML
  } catch (e) {
    return richText
  }
}
export default {
  components: {
    Questions,
    AnswerSheet,
    yxsSlider,
    invigilate,
    uploadCommon
  },
  data () {
    return {
      paperInfo: {
        // patrolFlag: true,
      }, // 考试信息
      time: 0, // 考试剩余时间
      exam_patroll: null, // 考试轮询时间
      isExam: false, // ture 考试,false 练习题
      paperType: true, // ture 做题,false 查看解析
      paperSubName: '', // 作业名称
      courseId: 0, // 考试id
      workId: 0, // 作业id
      studentExamId: 0, // 考试id
      richText:
        "<p>无</p><p><img src='https://file.zhihuischool.cn/f38e0707035243fbb28a5687a289cfaf.png' title='' alt=''/></p>",
      isWiating: false,
      showCountdown: false,
      show: false,
      isCollection: '',
      sumList: [],
      examList: [],
      answerCardList: [],
      examTotal: 0,
      currentExam: {},
      currentIndex: 0,
      showAnswerSheet: false,
      current: 1,
      size: 10,
      timeLeft: 600 * 1000,
      isClickToLast: false, // 是否点击题目到的最后一题
      lessonId: '', // 课时id
      chapterId: '',
      // courseId: '',
      resultId: '',
      needMarking: '',
      isOldVersion: false,
      correctShow: false, // 纠错弹窗
      radio: '0',
      message: '',
      fontSizeSheetShow: false,
      uploadValue: {},
      multipleList: [
        {
          multiple: 0.8,
          name: '小'
        },
        {
          multiple: 1,
          name: '标准',
          default: true
        },
        {
          multiple: 1.5,
          name: '大'
        },
        {
          multiple: 2,
          name: '超大'
        }
      ],
      checked: false,
      showUploadSelect: false
    }
  },
  beforeDestroy () {},
  watch: {
    '$store.state.isGetToken' (val) {
      const type = this.$store.state.deviceType
      console.log(type, 'type')
      if (val && type === 1) {
        // ios
        const isExam = this.$route.query.isExam
        if (isExam === 0 || isExam === '0') {
          // 考试
          console.log('aaa')
          this.isExam = true
        } else {
          this.isExam = false
        }
        if (isExam) {
          window.webkit.messageHandlers.getData.postMessage('isExam')
          window.webkit.messageHandlers.getData.postMessage('job_name')
          window.webkit.messageHandlers.getData.postMessage('job_Type')
          window.getIosJobName = this.getIosName
          window.getIosJobType = this.getIosType
          if (!this.isExam) {
            window.webkit.messageHandlers.getData.postMessage('courseid')
            window.getIosCourseId = this.getIosCourseId
          } else {
            window.webkit.messageHandlers.getData.postMessage('getData')
            window.getIosData = this.getIosData
            window.webkit.messageHandlers.getData.postMessage('job_id')
            window.getIosJobId = this.getIosId
          }
        }
      } else if (val && type === 2) {
        // 安卓
        this.getAnIsExam()
        this.getAnCourseId()
        // if (!this.isExam) {
        // }
        this.getAnType()
        this.getAnName()
        this.getAnId()
        // this.getLiveWorkData()
      } else {

      }
    }
  },
  computed: {
    ...mapGetters({ userSetting: 'userSetting' }),

    sumed () {
      // let uniqueArr = this.answerCardList.filter(item => (item.rightFlag === 1 || item.rightFlag === '1' || item.rightFlag === '0' || item.rightFlag === 0))
      // return uniqueArr.length
      return this.currentIndex + 1
    }
  },
  mounted () {
    // document.querySelector('#app').style.overflow = 'hidden'
  },
  created () {
    // console.log('本地测试')
    // // sessionStorage.setItem('token', '844d1fb5-b1f0-42cd-9ae7-c26a2b088f76')
    // // sessionStorage.setItem('tenant_id', 223)
    // this.paperSubName = '本地测试2'

    // this.workId = 1236188
    // // this.courseId = 2832
    // this.isExam = true
    // // this.paperInfo.exam_patroll = 5
    // // this.paperInfo.scoringStatus=1
    // this.paperType = true
    // // // false 解析
    // this.getPaper(this.workId, 1)

    // if (!this.userSetting.colorModeExam) {
    //   this.changeUserSetting({ 'colorModeExam': 'day' })
    // }
    // window.openModeSet = () => {
    //   this.fontSizeSheetShow = true
    // }
    // window.mFontSizeSetting = () => {
    //   this.fontSizeSheetShow = true
    // }
    // this.show = window.localStorage.getItem('tipShow') || false
    // this.userToken = getQueryString('userToken') || ''
    // this.tenantId = getQueryString('tedantId') || ''
    // this.examId = getQueryString('examId') || ''
    // this.lessonId = getQueryString('lessonId') || ''
    // this.chapterId = getQueryString('chapterId') || ''
    // this.courseId = getQueryString('courseId') || ''
    // this.questionFrom = getQueryString('questionFrom')
    // this.title = getQueryString('title') || '考试'

    // console.log('title', this.title)
    // document.title = this.title
    // console.log('document.title', document.title)
    // 夜间模式按钮回显
    // console.log('this.userSetting.colorModeExam', this.userSetting.colorModeExam)

    // if (this.userSetting.colorModeExam === 'day') {
    //   this.checked = false
    // } else {
    //   this.checked = true
    // }
    // if (!this.questionFrom && this.questionFrom !== '0' && this.questionFrom !== 0) {
    //   this.isOldVersion = true
    // }

    // 调试开发用参数 TODO: 上传前注释
    // this.userToken = '2a4291bd-ca51-48b5-863f-d23885516013'
    // // this.tenantId = 52
    // this.lessonId = 5660
    // this.examId = 13
    // this.questionFrom = 0

    // 是否重做
    // this.reForm = getQueryString('reform') || false

    // this.$store.commit('setToken', this.userToken)
    // this.$store.commit('setTenantId', this.tenantId)
    // console.log(this.$route.query);

    // if (this.userSetting.fontMultipleExam) {
    //   this.multipleList = this.multipleList.map(item => ({ ...item, default: item.multiple === this.userSetting.fontMultipleExam }))
    // }
  },
  methods: {
    ...mapActions(['changeUserSetting']),
    // *****************************智汇-新增开始
    // Android 获取参数
    getAnCourseId () {
      const courseId = window.Android.getCourseId()
      this.courseId = courseId
    },
    getAnId () {
      if (this.isExam) {
        // 考试id
        const workId = window.Android.getStudentExamId()
        this.workId = workId
        this.getPaper(workId, 1)
      } else {
        // 作业id
        const workId = window.Android.getWorkid()
        this.workId = workId
        this.getPaper(workId, 1)
      }
    },
    getAnName () {
      if (this.isExam) {
        const examName = window.Android.getExamName()
        this.paperSubName = examName
      } else {
        const workName = window.Android.getWorkName()
        this.paperSubName = workName
      }
    },
    getAnType () {
      const paperType = window.Android.getType()
      if (paperType === 0) {
        // 做题
        this.paperType = true
      } else if (paperType === 1) {
        // 预览
        this.paperType = false
      }
    },
    getAnIsExam () {
      const isExam = window.Android.isExam()
      if (isExam === 0) {
        // 考试
        this.isExam = true
        this.getAnData()
      } else if (isExam === 1) {
        // 练习题
        this.isExam = false
      }
    },
    getAnData () {
      let data
      try {
        if (window.Android.getData() != undefined && window.Android.getData()) {
          data = JSON.parse(window.Android.getData())
          this.paperInfo = data
        }
        if (data && data.id) {
          this.workId = parseInt(data.id)
          // this.getPaper(this.workId, 1)
        }
      } catch (e) {
      }
      // this.getAnIsExam(data)
      // if (data.courseId) {
      //   this.courseId = parseInt(data.courseId)
      // }
      // if (data.title) {
      //   this.paperSubName = data.title
      // }
      // if (data.status || data.status === 0 || data.status === '0') {
      //   if (parseInt(data.status) === 0) {
      //     // 做题
      //     this.paperType = true
      //   } else if (data.status == 1 || data.status == 2) {
      //     // 预览
      //     this.paperType = false
      //     if (data.time) {
      //       this.submitTime = data.time
      //     }
      //   }
      // }
    },
    // ios
    getIosId (iosWorkid) {
      this.workId = parseInt(iosWorkid)
      this.getPaper(iosWorkid, 1)
    },
    getIosCourseId (courseId) {
      this.courseId = courseId
      window.webkit.messageHandlers.getData.postMessage('job_id')
      window.getIosJobId = this.getIosId
    },
    getIosName (iosWorkName) {
      this.paperSubName = iosWorkName
    },
    getIosType (iosType) {
      if (iosType === 0 || iosType === '0') {
        // 做题
        this.paperType = true
      } else if (iosType === 1 || iosType === '1') {
        // 预览
        // alert('预览：' + iosType)
        this.paperType = false
      }
    },
    getIosIsExam (isExam) {
      if (isExam === 0) {
        // 考试
        this.isExam = true
      } else if (isExam === 1) {
        // 练习题
        this.isExam = false
      }
    },
    getIosData (data) {
      const data2 = JSON.parse(data)
      this.paperInfo = data2
    },
    // 提交作业/考试
    submitJob () {
      console.log(this.examList)
      const data = {
        answerElapsedTime: 0,
        workRecordId: this.examList[0].workRecordId
      }
      const data2 = {
        studentExamId: this.workId
        // workRecordId: this.examList[0].workRecordId
      }
      if (this.isExam) {
        Dialog.confirm({
          message: '交卷后将无法再答题，是否确认交卷？',
          confirmButtonColor: '#1A7EE2'
        }).then(() => {
          this.$request
            .putExamSubmit(data2)
            .then((res) => {
              if (this.paperInfo.submitTimeLimit && res.data.msg) {
                Dialog.alert({
                  message: res.data.msg,
                  confirmButtonText: '我已知悉',
                  confirmButtonColor: '#1A7EE2'
                })
              } else {
                Notify({ type: 'success', message: '提交成功' })
                const type = this.$store.state.deviceType
                try {
                  if (type === 1) {
                    window.webkit.messageHandlers.getData.postMessage('close()')
                  } else if (type === 2) {
                    window.Android.Close()
                  }
                } catch (error) {

                }
              }
            }).catch(() => {
              Notify({ type: 'danger', message: '提交失败' })
            })
        }).catch(() => {})
      } else {
        this.$request
          .putWorkSubmit(data)
          .then((res) => {
            Notify({ type: 'success', message: '提交成功' })
            const type = this.$store.state.deviceType
            try {
              if (type === 1) {
                window.webkit.messageHandlers.getData.postMessage('close()')
              } else if (type === 2) {
                window.Android.Close()
              }
            } catch (error) {

            }
          }).catch(() => {
            Notify({ type: 'danger', message: '提交失败' })
          })
      }
    },
    // 重新答题作业
    again () {
      const workId = 14609
      const data = {
        type: 2,
        workId: workId
      }
      this.getPaper(this.workId, 2)
    },
    // 开始考试
    beginExam () {
      const data = {
        studentExamId: parseInt(this.workId)
      }
      const data2 = {
        studentExamId: this.workId
        // type: type
      }
      const that = this
      function getExamPaper (data2) {
        console.log('getExamPaper')
        that.$request.getExamPaper(data2).then((res) => {
          that.initPaper(res)
        }).finally(() => {
          Toast.clear()
        })
      }
      if (this.paperType) {
        // 做题
        this.$request.putExamBegin(data).then((res) => {
          if (res.data.code === 0) {
            this.paperInfo = {
              ...this.paperInfo,
              ...res.data.data
            }
            console.log('putExamBegin', this.paperInfo)
            const remainTime = res.data.data.remainTime
            this.timeLeft = remainTime * 1000
            // 巡考开启
            if (res.data.data.patrolFlag == true) {
              this.paperInfo.patrolFlag = res.data.data.patrolFlag
              this.paperInfo.playUrl = res.data.data.playUrl
              const type = this.$store.state.deviceType
              const val = JSON.stringify(res.data.data)
              if (type === 1) {
                window.webkit.messageHandlers.startPush.postMessage(val)
              } else if (type === 2) {
                window.Android.startPush(val)
              }
            }
            if (res.data.data.submitTimeLimit) {
              // 巡考开启
              this.paperInfo.submitTimeLimit = res.data.data.submitTimeLimit
            }
            // this.getPaper(this.workId, 1)
            getExamPaper(data2)
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        getExamPaper(data2)
      }
    },
    // 倒计时主动交卷
    submitPaper () {
      const data = {
        studentExamId: parseInt(this.workId)
      }
      console.log('交卷')
      // return ;
      Dialog.alert({
        title: '温馨提示',
        message: '考试结束，已自动提交试卷'
      })
      this.submitJob()
    },
    // 上传点击
    handleUpload (val) {
      console.log(val, 'val')
      // this.showUploadSelect = !this.showUploadSelect
      this.uploadValue = val
      if (val.uploadType == 'imgClick') {
        if (this.paperType) {
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.uploadedActions
        } else {
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.onlyReadActions
        }
        this.$refs.uploadCommon.isVideo = false
        if (this.uploadValue.miniNum) {
          this.$refs.uploadCommon.previewList = this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls
        } else {
          this.$refs.uploadCommon.previewList = this.examList[this.uploadValue.num - 1].selfImgUrls
        }
        this.$refs.uploadCommon.startPosition = val.fillIdx
      } else if (val.uploadType == 'videoClick') {
        if (this.paperType) {
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.uploadedActions
        } else {
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.onlyReadActions
        }
        this.$refs.uploadCommon.videoInfo.id = val.id
        this.$refs.uploadCommon.videoInfo.source = val.selfVideoUrl
        // this.$refs.uploadCommon.isVideo = true
        this.$set(this.$refs.uploadCommon, 'isVideo', true)
      } else {
        if (val.typeId == 6) {
          if (this.uploadValue.miniNum) {
            this.$refs.uploadCommon.previewList = this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls || []
          } else {
            this.$refs.uploadCommon.previewList = this.examList[this.uploadValue.num - 1].selfImgUrls || []
          }
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.uploadingBothActions
        } else {
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.uploadingActions
        }
      }
      this.$refs.uploadCommon.showAction = !this.$refs.uploadCommon.showAction
    },
    // 上传选择结束
    uploadfinish (obj) {
      console.log(obj, 'obj2')
      console.log(this.uploadValue, 'uploadValue')
      let imgUrl = []
      let videoUrl = ''
      // if (this.uploadValue.childrenList.length>0) {

      // } else {
      // }
      // 只分问答或填空
      if (obj && obj.type && obj.type == 'video') {
        // 视频上传
        console.log('video')
        videoUrl = this.examList[this.uploadValue.num - 1].selfVideoUrl
        videoUrl = obj.excelUrl
      } else if (this.uploadValue.uploadType == 'img') {
        if (this.uploadValue.fillNum || this.uploadValue.fillNum === 0) {
          // 填空
          if (this.uploadValue.miniNum || this.uploadValue.miniNum == 0) {
            // 大题下填空
            if (this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls) {
              // 存在过图片上传 同步数组
              imgUrl = this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls
            }
            console.log('大题上传', imgUrl)
          } else {
            if (this.examList[this.uploadValue.num - 1].selfImgUrls) {
              // 存在过图片上传 同步数组
              imgUrl = this.examList[this.uploadValue.num - 1].selfImgUrls
            }
          }
          if (this.uploadValue.fillIdx <= imgUrl.length - 1) {
            imgUrl[this.uploadValue.fillIdx] = obj.excelUrl
          } else {
            imgUrl.push(obj.excelUrl)
          }
        } else {
          // 问答
          if (this.uploadValue.miniNum || this.uploadValue.miniNum == 0) {
            if (this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls && this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls.length > 0) {
              // 初始化 数组
              imgUrl = this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls
            }
          } else {
            if (this.examList[this.uploadValue.num - 1].selfImgUrls && this.examList[this.uploadValue.num - 1].selfImgUrls.length > 0) {
              // 初始化 数组
              imgUrl = this.examList[this.uploadValue.num - 1].selfImgUrls
            }
          }
          // if (obj.length > 0) {
          //   for (let i in obj) {
          //     imgUrl.push(obj[i].excelUrl)
          //   }
          // } else {
          // }
          imgUrl.push(obj.excelUrl)
        }
      } else if (obj == 'delImg') {
        if (this.uploadValue.miniNum || this.uploadValue.miniNum == 0) {
          imgUrl = this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls
        } else {
          imgUrl = this.examList[this.uploadValue.num - 1].selfImgUrls
        }
        if (this.uploadValue.typeId == 5) {
          imgUrl[this.uploadValue.fillIdx] = ''
        } else if (this.uploadValue.typeId == 6) {
          if (imgUrl && imgUrl.length > 0) {
            imgUrl.splice(this.uploadValue.fillIdx, 1)
          }
        }
      }
      console.log(imgUrl, 'imgUrl', !!imgUrl)
      const que = {
        id: this.uploadValue.id,
        selfAnswer: this.uploadValue.selfAnswer,
        selfImgUrls: imgUrl,
        selfVideoUrl: videoUrl
      }
      this.saveRecord(que).then((res) => {
        console.log(res, '上传保存成功')
        if ((obj.length == 1 && obj[0].type && obj[0].type == 'video') || obj == 'delVideo') {
          if (this.uploadValue.miniNum || this.uploadValue.miniNum == 0) {
            this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
            this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
          } else {
            this.examList[this.uploadValue.num - 1].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
            this.examList[this.uploadValue.num - 1].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
          }
          console.log(videoUrl, 'videoUrl')
        } else if (this.uploadValue.uploadType == 'img' || obj == 'delImg') {
          if (this.uploadValue.miniNum || this.uploadValue.miniNum == 0) {
            // 大题
            this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
            this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
            this.answerCardList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
            this.answerCardList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
          } else {
            this.examList[this.uploadValue.num - 1].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
            this.examList[this.uploadValue.num - 1].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
            this.answerCardList[this.uploadValue.num - 1].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
            this.answerCardList[this.uploadValue.num - 1].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
          }
          // this.$set(this.examList[this.uploadValue.num - 1],'selfImgUrls',imgUrl)
          console.log(imgUrl, 'imgUrl', videoUrl)
        }
      })
    },
    // *****************************智汇-新增结束
    wyChange () {
      // console.log(11111);
      // window.addEventListener('resize', () => {
      // if (document.activeElement.tagName == 'INPUT') {
      //   window.setTimeout(() => {
      //     console.log(555)
      //     document.activeElement.scrollIntoViewIfNeeded()
      //     console.log(666)
      //   }, 500)
      // }
      // })
      // document.querySelector('.wybc').scrollIntoView()
    },
    // // 夜间模式切换
    // changeMode (mode) {
    //   var str = ''
    //   if (mode) {
    //     str = 'night'
    //     // window.location.href = 'sendmessage/setcolormodeexam?mode=night'
    //   } else {
    //     str = 'day'
    //     // window.location.href = 'sendmessage/setcolormodeexam?mode=day'
    //   }
    //   this.changeUserSetting({ 'colorModeExam': str })
    //   this.examList = this.examList.map(ele => {
    //     if (ele.typeId === 7) {
    //       return {
    //         ...ele,
    //         colorMode: str,
    //         childrenList: ele.childrenList.map(item => ({
    //           ...item,
    //           colorMode: str
    //         }))
    //       }
    //     }
    //     return {
    //       ...ele,
    //       colorMode: str
    //     }
    //   })
    // },
    // onProgressChange (multipeInfo) {
    //   console.log(multipeInfo)
    //   this.changeUserSetting({ 'fontMultipleExam': multipeInfo.multiple })
    //   this.examList = this.examList.map(ele => {
    //     if (ele.typeId === 7) {
    //       return {
    //         ...ele,
    //         fontSizeMultiple: multipeInfo.multiple,
    //         childrenList: ele.childrenList.map(item => ({
    //           ...item,
    //           fontSizeMultiple: multipeInfo.multiple
    //         }))
    //       }
    //     }
    //     return {
    //       ...ele,
    //       fontSizeMultiple: multipeInfo.multiple
    //     }
    //   })
    // },
    /**
     * 获取答题卡列表
     */
    getPaper (workId, type) {
      Toast.loading({
        duration: 0,
        message: '题目生成中'
      })
      const data = {
        workId: workId,
        courseId: this.courseId,
        type: type
      }
      if (this.isExam) {
        this.beginExam()
      } else {
        this.$request
          .getWorkList(data)
          .then((res) => {
            this.initPaper(res)
          })
          .finally(() => {
            Toast.clear()
          })
      }
    },
    // 题目初始化
    initPaper (res) {
      console.log(res.data.data, 'res0')
      if (res.data.code !== 0) {
        return
      }
      // if (Date.parse(new Date(res.data.examResult.endTime)) - Date.parse(new Date(res.data.examResult.updateTime)) < 0) {
      //   // TODO: 处理考试已结束的情况
      // }

      // if (!res.data.examResult.updateTime) {
      //   res.data.examResult.updateTime = res.data.examResult.createTime // 如果 updateTime 为空，便用createTime计算考试倒计时
      // }

      // // 考试剩余时间
      // this.timeLeft = Date.parse(new Date(res.data.examResult.endTime.replace(/-/g, '/'))) - Date.parse(new Date(res.data.examResult.updateTime.replace(/-/g, '/')))

      // this.showCountdown = true
      // this.$nextTick(() => {
      //   this.$refs['count-down'].start() // 开始计时
      // })

      res.data.data = res.data.data.map((question, childrenIndex) => {
        if (
          question.typeId === 1 ||
          question.typeId === 2 ||
          question.typeId === 3
        ) {
          // 单选，多选，判断
          console.log(childrenIndex, question.optionsContent)
          try {
            const test = JSON.parse(question.optionsContent)
            console.log(test)
          } catch (e) {
            console.log('e', e)
            return {
              ...question,
              optionContents: null,
              // stem: addAttr(
              //   addPrefix(question.stem, childrenIndex + 1 + ".")
              // ),
              analysis: addAttr(question.analysis),
              fontSizeMultiple: this.userSetting.fontMultipleExam
                ? this.userSetting.fontMultipleExam
                : 1,
              colorMode: this.userSetting.colorModeExam
                ? this.userSetting.colorModeExam
                : 'day'
            }
          }
          return {
            ...question,
            optionContents: JSON.stringify(
              JSON.parse(question.optionsContent).map((optionItem) => ({
                ...optionItem,
                content: addAttr(optionItem.content)
              }))
            ),
            // stem: addAttr(
            //   addPrefix(question.stem, childrenIndex + 1 + ".")
            // ),
            analysis: addAttr(question.analysis),
            fontSizeMultiple: this.userSetting.fontMultipleExam
              ? this.userSetting.fontMultipleExam
              : 1,
            colorMode: this.userSetting.colorModeExam
              ? this.userSetting.colorModeExam
              : 'day'
          }
        } else {
          // 除选择其他题型
          return {
            ...question,
            // stem: addAttr(
            //   addPrefix(question.stem, childrenIndex + 1 + ".")
            // ),
            selfImgUrls: question.selfImgUrls ? question.selfImgUrls.split(',') : question.selfImgUrls,
            analysis: addAttr(question.analysis),
            fontSizeMultiple: this.userSetting.fontMultipleExam
              ? this.userSetting.fontMultipleExam
              : 1,
            colorMode: this.userSetting.colorModeExam
              ? this.userSetting.colorModeExam
              : 'day'
          }
        }
      })
      console.log(res.data.data, 'res1')
      // 大题数据处理
      res.data.data = res.data.data.map((question) => {
        if (
          (question.typeId === 7 || question.typeId === 8) &&
            question.childrenList &&
            question.childrenList.length > 0
        ) {
          return {
            ...question,
            selfAnswer: question.childrenList.some(
              (item) => item.selfAnswer
            ),
            childrenList: question.childrenList.map(
              (childrenItem, childrenIndex) => {
                if (
                  childrenItem.typeId === 1 ||
                  childrenItem.typeId === 2 ||
                  childrenItem.typeId === 3
                ) {
                  // 单选，多选，判断
                  return {
                    ...childrenItem,
                    optionContents: JSON.stringify(
                      JSON.parse(childrenItem.optionsContent).map(
                        (optionItem) => ({
                          ...optionItem,
                          content: addAttr(optionItem.content)
                        })
                      )
                    ),
                    // stem: addAttr(
                    //   addPrefix(
                    //     childrenItem.stem,
                    //     "（" + (childrenIndex + 1) + "）"
                    //   )
                    // ),
                    analysis: addAttr(childrenItem.analysis),
                    fontSizeMultiple: this.userSetting.fontMultipleExam
                      ? this.userSetting.fontMultipleExam
                      : 1,
                    colorMode: this.userSetting.colorModeExam
                      ? this.userSetting.colorModeExam
                      : 'day'
                  }
                } else {
                  return {
                    ...childrenItem,
                    // stem: addAttr(
                    //   addPrefix(
                    //     childrenItem.stem,
                    //     "（" + (childrenIndex + 1) + "）"
                    //   )
                    // ),
                    selfImgUrls: childrenItem.selfImgUrls ? childrenItem.selfImgUrls.split(',') : childrenItem.selfImgUrls,
                    analysis: addAttr(childrenItem.analysis),
                    fontSizeMultiple: this.userSetting.fontMultipleExam
                      ? this.userSetting.fontMultipleExam
                      : 1,
                    colorMode: this.userSetting.colorModeExam
                      ? this.userSetting.colorModeExam
                      : 'day'
                  }
                }
              }
            )
          }
        }
        return {
          ...question,
          childrenList: []
        }
      })

      // console.log(res.data.data, "<><<<<");

      if (_.isArray(res.data.data) && res.data.data.length > 0) {
        // TODO: 处理试卷没有考题的情况
      }

      if (res.data.data.every((item) => item.answer)) {
        // 题目全部答完了
        // TODO: 处理试题全部做完了的情况
      }

      const lastQuestionIndex = _.findIndex(
        res.data.data,
        (item) => item.lastFlag === 1 || item.lastFlag === '1'
      )

      this.$refs.questions.$refs.swiper.swipeTo(lastQuestionIndex)
      // console.log('cardList', _.cloneDeep(res.data))
      this.answerCardList = res.data.data
      this.examList = res.data.data
      // console.log(this.examList, 'examlist0')
      this.examTotal = this.answerCardList.length
      // this.resultId = res.data.examResult.id
      this.needMarking = res.data.needMarking
      this.init()
      // if (type === 2) {
      //   this.toCurrentExam(1)
      // }
    },
    /**
     * 倒计时交卷
     */
    onExamFinished () {
      console.log('提交试卷')
      this.$api.exam
        .submitExam({
          examPaperId: this.examId,
          examResultId: this.resultId,
          needMarking: this.needMarking
        })
        .then((res) => {
          // TODO: 接口返回错误处理
          Dialog.alert({
            title: '温馨提示',
            message: '考试结束，已自动提交试卷'
          }).then(() => {
            //  window.location.href = 'sendmessage/setcolormodeexam?mode=day'
            // this.$router.replace({ path: '/exam/examResult', query: { examResultId: this.resultId, userToken: this.userToken, tedantId: this.tenantId } })
            window.location.replace(
              `/exam/examResult?examResultId=${this.resultId}&userToken=${this.userToken}&tedantId=${this.tenantId}`
            )
          })
        })
    },
    /**
     * 主动交卷
     */
    submitExam () {
      Dialog.confirm({
        title: '温馨提示',
        message: '确定提交试卷吗？',
        className: `${
          this.userSetting.colorModeExam === 'day' ? 'day' : 'night'
        }`
      })
        .then(() => {
          console.log('confirm')
          console.log('examId')
          console.log(this.examId)
          console.log('examId')
          console.log('examResultId')
          console.log(this.examResultId)
          console.log('examResultId')
          console.log('needMarking')
          console.log(this.needMarking)
          console.log('needMarking')
          console.log('提交试卷')
          this.$api.exam
            .submitExam({
              examPaperId: this.examId,
              examResultId: this.resultId,
              needMarking: this.needMarking
            })
            .then((res) => {
              // TODO: 接口异常处理
              Dialog.alert({
                title: '温馨提示',
                message: '已提交试卷'
              }).then(() => {
                this.$router.replace({
                  path: '/exam/examResult',
                  query: {
                    examResultId: this.resultId,
                    userToken: this.userToken,
                    tedantId: this.tenantId
                  }
                })
              })
            })
        })
        .catch(() => {
          // console.log("cancel");
        })
    },
    xx () {},
    clickKnow () {
      this.show = true
      // 引导页载入缓存
      window.localStorage.setItem('tipShow', true)
    },
    // 选择、作答回调
    questionResult (val) {
      console.log(val, 'val')
      let valQ = val
      if (val.typeId === 7 || val.typeId === 8) {
        valQ = val.childrenList[val.miniNum]
      }
      this.saveRecord(valQ).then((res) => {
        console.log(val, 'valQ2')
        const list = this.examList
        this.answerCardList = list
        if (val.typeId === 7 || val.typeId === 8) {
          // 大题
          // console.log(this.examList[this.currentIndex].childrenList[val.miniNum].selfAnswer, 'val.selfAnswer11')
          console.log(val.selfAnswer, 'val.selfAnswer1', val.num)
          this.examList[val.num - 1].childrenList[val.miniNum].selfAnswer = JSON.parse(JSON.stringify(valQ.selfAnswer))
          const childList = this.examList[val.num - 1].childrenList
          this.examList[val.num - 1].childrenList = JSON.parse(JSON.stringify(childList))

          this.answerCardList[val.num - 1].childrenList[val.miniNum].selfAnswer = JSON.parse(JSON.stringify(valQ.selfAnswer))
          const cardList = this.examList[val.num - 1].childrenList
          this.answerCardList[val.num - 1].childrenList = JSON.parse(JSON.stringify(cardList))
          // this.$set(this.answerCardList[val.num - 1].childrenList[val.miniNum], 'selfAnswer', valQ.selfAnswer)
        } else {
          // 小题
          console.log(val.selfAnswer, 'val.selfAnswer2', val.num)
          this.examList[val.num - 1].selfAnswer = JSON.parse(JSON.stringify(val.selfAnswer))
          this.answerCardList[val.num - 1].selfAnswer = JSON.parse(JSON.stringify(val.selfAnswer))
          // this.$set(this.answerCardList[val.num - 1], 'selfAnswer', val.selfAnswer)
        }
        console.log(this.examList)
        console.log(this.answerCardList)
        // 最后一题
        if (this.currentIndex === this.examList.length - 1) {
          if (val.parentQuestion && (val.parentQuestion.typeId === 7 || val.parentQuestion.typeId === 8)) {
            let flag = false
            if (val.parentQuestion && val.parentQuestion.childrenList.length > 0) {
              const num = 0
              val.childrenList.forEach(item => {
                if (item.selfAnswer !== '' || item.selfAnswer !== null) {
                  // ++num
                  flag = true
                  // if (num === val.childrenList.length) {
                  // }
                }
                // console.log(flag, 'flag', num)
              })
            }
            if (!flag) {
              Toast({
                message: '已经到最后一题啦！',
                duration: 1000
              })
            }
          } else {
            Toast({
              message: '已经到最后一题啦！',
              duration: 1000
            })
          }
        }
        if (val.typeId === 1 || val.typeId === 4) {
          if (val.parentQuestion) {
            // console.log('有小题')
          } else {
            // console.log('无小题')
            this.$nextTick((_) => {
              this.$refs.questions.$refs.swiper.next()
            })
          }
        }
      })
      return
      if (
        (val.parentQuestion && val.parentQuestion.typeId == 7) ||
        (val.parentQuestion && val.parentQuestion.typeId == 8)
      ) {
        console.log('进入了配伍题提交')
        console.log(val)
        this.saveRecord(val)
          .then((res) => {
            this.examList[this.currentIndex].selfAnswer = true
            this.answerCardList = this.examList
            return this.saveRecord({ ...val.parentQuestion, answer: true })
          })
          .then((res) => {
            console.log(res, '<<<<<')
          })
      } else {
        console.log('非7 8')
        this.saveRecord(val).then((res) => {
          this.examList[this.currentIndex].selfAnswer = val.selfAnswer
          this.answerCardList = this.examList
          if (this.currentIndex === this.examList.length - 1) {
            Toast({
              message: '已经到最后一题啦！',
              duration: 1000
            })
          }
          if (val.typeId === 1 || val.typeId === 4) {
            this.$nextTick((_) => {
              this.$refs.questions.$refs.swiper.next()
            })
          }
        })
      }
      // 练习题保存答案
    },
    questionIndex (val) {
      this.currentIndex = val
      this.init()
    },
    init () {
      this.currentExam = this.examList[this.currentIndex]
      // console.log(this.currentExam, "9999");
      this.isCollection =
        this.currentExam.collectionStatus === 1 ||
        this.currentExam.collectionStatus === '1'
    },
    // // 纠错
    // openCorrect () {
    //   this.message = ''
    //   this.radio = '0'
    //   this.correctShow = true
    // },
    // correctSumbit () {
    //   this.$api.exam.correct({
    //     questionId: this.examList[this.currentIndex].id,
    //     questionType: this.examList[this.currentIndex].questionType,
    //     type: this.radio,
    //     title: this.message,
    //     lessonId: this.lessonId,
    //     chapterId: this.chapterId,
    //     courseId: this.courseId
    //   }).then(res => {
    //     console.log(res, '纠错')
    //     if (res.code === 0 && res.data) {
    //       Toast({
    //         message: '纠错成功',
    //         duration: 1000
    //       })
    //       this.correctShow = false
    //     }
    //   })
    // },
    // // 收藏
    // collect () {
    //   this.$api.exam.examCollect({
    //     examPaperId: this.examId,
    //     questionFrom: this.questionFrom,
    //     questionId: this.examList[this.currentIndex].id,
    //     typeId: this.examList[this.currentIndex].typeId
    //   })
    //     .then(res => {
    //       console.log('收藏', res)
    //       if (res.code === 0 && res.data) {
    //         Toast({
    //           message: '收藏成功',
    //           duration: 1000
    //         })
    //         this.examList[this.currentIndex].collectionId = res.data.collectionId
    //         this.examList[this.currentIndex].collectionStatus = 1
    //         this.init()
    //       } else {
    //         Toast({
    //           message: res.msg,
    //           duration: 1000
    //         })
    //       }
    //     })
    // },
    // // 取消收藏
    // cancelCollect () {
    //   this.$api.exam.examUncollect({
    //     examPaperId: this.examId,
    //     questionFrom: this.questionFrom,
    //     questionId: this.examList[this.currentIndex].id
    //   })
    //     .then(res => {
    //       if (res.code === 0 && res.data) {
    //         Toast({
    //           message: '取消收藏成功',
    //           duration: 1000
    //         })
    //         this.isCollection = 0
    //         this.examList[this.currentIndex].collectionStatus = 0
    //         this.init()
    //       } else {
    //         Toast({
    //           message: res.msg,
    //           duration: 1000
    //         })
    //       }
    //     })
    // },
    // 保存做题记录
    saveRecord (question) {
      // console.log(question, 'save')
      // return this.$api.exam.saveAnswer({
      //   examPaperId: this.examId,
      //   questionId: question.id,
      //   score: question.score,
      //   answer: question.selfAnswer,
      //   typeId: question.typeId,
      //   refAnswer: question.answer,
      //   resultId: this.resultId,
      //   parentId: question.parentId
      // })
      if (this.isExam) {
        console.log('考试答题')
        return this.$request.putExamAnswer({
          studentExamId: this.workId,
          questionId: question.id,
          selfAnswer: question.selfAnswer,
          selfImgUrls: question.selfImgUrls ? (question.selfImgUrls).join(',') : question.selfImgUrls,
          selfVideoUrl: question.selfVideoUrl
        })
      } else {
        console.log('作业答题')
        return this.$request.putWorkAnswer({
          id: question.id,
          selfAnswer: question.selfAnswer,
          selfImgUrls: question.selfImgUrls ? (question.selfImgUrls).join(',') : question.selfImgUrls,
          selfVideoUrl: question.selfVideoUrl
        })
      }
    },
    // 上一题
    prev () {
      if (this.currentIndex === 0) {
        Toast({
          message: '已经到第一道题啦!',
          duration: 1000
        })
        return
      }
      this.$nextTick((_) => {
        this.$refs.questions.$refs.swiper.prev()
      })
    },
    // 下一题
    next () {
      if (this.currentIndex === this.examList.length - 1) {
        Toast({
          message: '已经到最后一题啦！',
          duration: 1000
        })
      }
      this.$nextTick((_) => {
        this.$refs.questions.$refs.swiper.next()
      })
    },
    topicShow () {
      this.showAnswerSheet = true
    },
    topicHide () {
      this.showAnswerSheet = false
    },
    // 跳转到指定题目
    toCurrentExam (index) {
      this.isClickToLast = true
      this.$nextTick((_) => {
        this.$refs.questions.$refs.swiper.swipeTo(index)
        this.topicHide()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.van-dialog.night {
  background: #404040;
  .van-dialog__header {
    color: #ddd;
  }
  .van-dialog__message {
    color: #ddd;
  }
  .van-button {
    background: #404040;
    color: #ddd;
    &.van-dialog__confirm {
      color: #1989fa;
    }
  }
}
.van-cell:not(:last-child)::after {
  border-bottom: none !important;
}
</style>
<style lang="scss" scoped>
.uploadCommon {
  // height: 100vh;
  // position: fixed;
  // width: 100vw;
  // top: 0;
}
.icon-class-small {
  width: 18px !important;
  height: 18px !important;
}
.topics {
  font-size: 12px;
  color: #333;
  span {
    color: #999;
  }
  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto 5px;
  }
}
.exam-container {
  ::v-deep .content-action-sheet {
    // height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
    .nightBox {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
      .nightMode {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(61, 61, 61, 1);
      }
    }
    .wzsf {
      height: 18px;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(61, 61, 61, 1);
      line-height: 18px;
      padding: 15px 0;
    }
  }
}
.exam-countdown {
  padding: 5px;
  text-align: center;
}
.container {
  width: 100%;
  height: 100vh;
  background: #fff;
  padding: 0;
}
.shadow {
  width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.4);
  z-index: 11;
  .tip {
    padding: 255px 15px 0;
    overflow: hidden;
    color: #eee;
    .left {
      float: left;
      img {
        display: block;
        width: 42px;
        height: 60px;
        padding-top: 22px;
        margin: 0 auto;
      }
    }
    .right {
      float: right;
      img {
        display: block;
        width: 38px;
        height: 60px;
        padding-top: 22px;
        margin: 0 auto;
      }
    }
  }
  .know {
    width: 127px;
    line-height: 40px;
    margin: 83px auto 0;
    background: #fff;
    color: #18a6f3;
    text-align: center;
    border: 1px solid #18a6f3;
    border-radius: 20px;
  }
}
.subhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 14px 0;
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 19px;
  color: #FFFFFF;
  .back {
    width: 12px;
  }
}
.datika {
  margin: 0 19px 14px;
  padding-top: 18px;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 15px;
    color: #FFFFFF;
    .daojishi {
      .van-count-down {
        color: #FFEF99;
        display: inline;
      }
    }
  }
  .right {
    // display: flex;
    // justify-content: end;
    // flex: 1;
    .answerSheetBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 123px;
      img {
        width: 100%;
      }
    }
  }
}

.lastExam {
  // margin-top: 50px;
  width: 100%;
  font-size: 15px;
  color: #333;
  text-align: center;
  position: absolute;
  bottom: 60px;
}
.bt-collect {
  text-align: center;
}
.bottom {
  width: 100%;
  position: fixed;
  bottom: 40px;
  z-index: 10;

  .collect {
    width: 48px;
    font-size: 12px;
    color: #333;
    text-align: center;
    img {
      display: block;
      width: 14px;
      height: 13px;
      margin: 0 auto 5px;
    }
  }
  .submit {
    font-size: 12px;
    color: #18a6f3;
    img {
      display: block;
      width: 14px;
      height: 14px;
      margin: 0 auto 5px;
    }
  }
  .submit-paper-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .submit-paper {
    font-size: 13px;
  }
  .topics {
    font-size: 0.3rem;
    color: #363840;
  }
}
</style>
<style lang="scss">
.exam-container {

}
.van-dialog__header {
  padding-top: 8px;
}
.btn {
  display: flex;
  justify-content: space-around;
  // width: 348px;
  align-items: center;
  // margin: 30px 0 10px;
  position: absolute;
  width: 100%;
  bottom: 20px;
  button {
    width: 160px;
    height: 42px;
    border-radius: 21px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 19px;
    box-sizing: border-box;
    border: 1px solid;
  }
  .prev {
    color: #486CEF;
    border-color:  #466FF6;
    background-color: #fff;
  }
  .next {
    background: linear-gradient(0deg, #3F63EA, #5579F6);
    border-color:  transparent;
    color: #FFFFFF;
  }
}
.correctType {
  height: 20px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(138, 138, 142, 1);
  line-height: 20px;
  margin-top: 20px;
  padding-left: 20px;
  margin-bottom: 15px;
}
.content .van-radio__label {
  word-break: break-all;
}
.correctPop {
  &.night {
    background: #404040;
    .van-radio__label {
      color: #ddd;
    }
    .van-field__control {
      background: #ccc;
    }
  }
  &.day {
    background: #fff;
  }
  .van-radio:nth-child(1),
  .van-radio:nth-child(3) {
    width: 48%;
    height: 40px;
    margin-left: 20px;
    line-height: 40px;
  }
  .van-radio:nth-child(2),
  .van-radio:nth-child(4) {
    height: 40px;
    line-height: 40px;
  }
  .van-field__control {
    background: rgba(247, 247, 247, 1);
    border-radius: 16px;
    text-indent: 20px;
  }
  .submit {
    width: 170px;
    height: 36px;
    background: rgba(24, 166, 243, 1);
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    line-height: 36px;
    margin-left: calc(50% - 85px);
    text-align: center;
    margin-top: 5px;
  }
}
</style>
