<!-- 考试巡考组件 -->
<template>
  <div class="invigilateWarp">
    <div class="warnBox" v-if="actionType=='ABNORMAL'">
      <div class="warn" ><img src="@/assets/img/warn.png" alt=""> {{msg||'系统未检测到本人人脸，请注意'}}</div>
    </div>
    <div class="invigilate">
      <div class="invigilate_text" v-if="info.patrolFlag==true"><img  class="icon1" src="@/assets/img/videoRed2.png" alt="" srcset="">实时监考中</div>
      <div class="invigilate_text" v-if="info.cuttingScreenFlag==1"><img class="icon2" src="@/assets/img/colon2.png" alt="" srcset="">请勿切屏</div>
      <!-- <div class="invigilate_box" ref="invigilateBox" draggable="true" :style="{top: top  + 'px', left: left  + 'px'}" @touchstart="dragStart()" @touchmove="dragMove()" @touchend="dragEnd()">
        <div class="videoBox">
          <commonPlay ref="commonPlay" v-if="videoInfo.source" :item="videoInfo"></commonPlay>
        </div>
      </div> -->
    </div>
    <van-dialog v-model="showWarn" confirmButtonText="收到" @confirm="onConfirm">
      <template #title>
        <div class="dialogTitle">
          <img src="@/assets/img/warnBlue.png" alt="" srcset="">
          巡考老师警告
        </div>
      </template>
      {{msg || '同学你在干嘛，请注意考试纪律'}}
    </van-dialog>
    <van-dialog v-model="showBack" confirmButtonText="确定" @confirm="onConfirm">
      <template #title>
        <div class="dialogTitle">
          <img class="warnRed" src="@/assets/img/warnRed.png" alt="" srcset="">
        </div>
      </template>
      <template v-if="actionType=='COMPULSORY_SUBMISSION'">{{msg|| '你已被巡考老师强制提前交卷， 点击确定后'}} </template>
      <template v-else>{{msg ||'你已被巡考老师记为作弊，当前考试记为0分，点击确定后返回'}} </template>
    </van-dialog>
  </div>
</template>
<script>
import commonPlay from '@/components/commonModule/upload/commonPlay'
export default {
  name: 'invigilate',
  components: {
    commonPlay
  },
  props: {
    info: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    info: {
      deep: true,
      handler (newV, oldV) {
        console.log(newV, 'info')
        if (newV) {
          this.infoChild = newV
        }
        // if (newV.playUrl) {
        //   this.videoInfo.source = newV.playUrl
        // }
      }
    },
    actionType: {
      deep: true,
      handler (newV, oldV) {
        console.log(newV, 'info')
        if (newV) {
          // this.showWarn.get()
          // this.showBack.get()
        }
      }
    }
  },
  computed: {
    showWarn: {
      get () {
        if (this.actionType == 'WARN') {
          return true
        } else {
          return false
        }
      },
      set () {}
    },
    showBack: {
      get () {
        if (this.actionType == 'COMPULSORY_SUBMISSION' || this.actionType == 'MARK_CHEAT') {
          return true
        } else {
          return false
        }
      },
      set () {}
    }
  },
  data () {
    return {
      infoChild: {},
      setInter: null,
      actionType: '',
      msg: '',
      infoChild: {}
      // videoInfo: {
      //   id: 'Invi',
      //   source: '',
      //   height: '108px',
      //   width: '80px',
      //   autoplay: true,
      //   isLive: true,
      // },
      // isdrag:true,
      // top: 150,
      // left: 0,
      // position: {x: 0, y: 150, left: 0, top: 150},
      // width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      // height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    }
  },
  created () {
  },
  mounted () {
    // this.left = this.width - 80
    // this.position.left = this.width - 80
    this.infoChild = this.info
    if (this.infoChild.exam_patrol) {
      this.setInter = setInterval(() => {
        if (this.info) {
          this.getExamPullMsg()
        }
      }, parseInt(this.infoChild.exam_patrol) * 1000)
    }
  },
  beforeDestro () {
    clearInterval(this.setInter)
  },
  methods: {
    // dragStart() {
    //   this.isdrag = true
    //   const refs = this.$refs.invigilateBox.getBoundingClientRect()
    //   console.log(refs);
    //   let touch = event
    //   if (event.touches) {
    //     touch = event.touches[0]
    //   }
    //   this.position.x = touch.clientX
    //   this.position.y = touch.clientY
    //   this.position.left = refs.left
    //   this.position.top = refs.top
    //   console.log(this.position);
    // },

    // dragMove() {
    //   if (this.isdrag) {
    //     let touch = event
    //     if (event.touches) {
    //       touch = event.touches[0]
    //     }
    //     const xPum = this.position.left + touch.clientX - this.position.x
    //     const yPum = this.position.top + touch.clientY - this.position.y
    //     this.left = xPum
    //     this.top = yPum
    //     // if (Number.isInteger(xPum)) {
    //     // }
    //     console.log(this.left,this.top,'move');
    //     this.banOut()
    //     // 阻止页面的滑动默认事件
    //     document.addEventListener('touchmove', function () {
    //       event.preventDefault()
    //     }, {passive: false})
    //   }
    // },

    // dragEnd() {
    //   this.isdrag = false
    //   console.log('end');
    //   this.banOut()
    // },
    // banOut () { // 避免拖动出界的限制
    //   const refs = this.$refs.invigilateBox.getBoundingClientRect()
    //   if (this.left < 0) {
    //     this.left = 0
    //   } else if (this.left > this.width - refs.width) {
    //     this.left = this.width - refs.width
    //   }
    //   if (this.top < 0) {
    //     this.top = 0
    //   } else if (this.top > this.height - refs.height) {
    //     this.top = this.height - refs.height
    //   }
    // },
    getExamPullMsg () { // 巡考拉流信息
      const data = {
        studentExamId: this.infoChild.id
      }
      this.$request.getExamPullMsg(data).then((res) => {
        if (res.data.code == 0) {
          console.log(typeof res.data.data)
          if (res.data.data) {
            const data = JSON.parse(res.data.data)
            this.actionType = data.actionType
            this.msg = data.msg
            console.log(this.actionType, this.msg)
            if (data.actionType == 'ABNORMAL') {
              // data.actionType=='WARN'||
              // 能考试 (提出警告、标记异常)
              setTimeout(() => {
                this.actionType = ''
              }, 3000)
            } else if (data.actionType == 'COMPULSORY_SUBMISSION' || data.actionType == 'MARK_CHEAT') {
              // 不能考试 (强制交卷、标记作弊)
              // 提交考试
              this.onsubmit()
              clearInterval(this.setInter)
            }
          }
        }
      }).catch((err) => {

      })
    },
    onsubmit () {
      const data = {
        studentExamId: this.infoChild.id
      }
      this.$request.putExamSubmit(data)
        .then((res) => {
          Notify({ type: 'success', message: '提交成功' })
        }).catch(err => {
          Notify({ type: 'danger', message: '提交失败' })
          this.onsubmit()
        })
    },
    onConfirm () {
      if (this.actionType == 'WARN') {
        console.log('警告')
      } else if (this.actionType == 'COMPULSORY_SUBMISSION' || this.actionType == 'MARK_CHEAT') {
        console.log('返回上一页')
        const type = this.$store.state.deviceType
        if (type === 1) {
          window.webkit.messageHandlers.getData.postMessage('close()')
        } else if (type === 2) {
          window.Android.Close()
        }
      }
      this.actionType = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.invigilateWarp {
  ::v-deep .van-dialog__content {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 24px;
    text-align: center;
    margin-bottom: 30px;
    margin: 0 50px 30px ;
  }
  .dialogTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 18px;
    .warnRed {
      width: 44px;
      height: 40px;
    }
    img {
      width: 20px;
      height: 18px;
      margin-right: 6px;
    }
  }
  .warnBox {
    display: flex;
    justify-content: center;
    .warn {
      background: rgba(226,117,26,0.1);
      // position: absolute;
      border-radius: 12px;
      padding: 5px 10px;
      z-index: 55;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #E2751A;
      line-height: 14px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
  .invigilate {
    display: flex;
    .invigilate_text {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #FFEF99;
      display: flex;
      align-items: center;
      margin-right: 15px;
      .icon1 {
        width: 12px;
        padding-right: 5px;
      }
      .icon2 {
        width: 16px;
        padding-right: 5px;
      }
    }
    .invigilate_box {
      width: 80px;
      height: 108px;
      background: #3D96F3;
      border-radius: 4px;
      position: absolute;
      right: 0;
      top: 150px;
      z-index: 55;
      .videoBox {

      }
    }
  }
}
</style>
